@import 'antd/lib/style/themes/default.less';
@import 'antd/dist/antd.less';

// @import 'antd/dist/antd.dark.less';
input{
    user-select: auto !important;
    -webkit-user-select: auto !important;
}

@primary-color: #1d4a99;
@border-radius-base: 5px;

#root{
    height: 100% !important;
    // overflow: hidden;
}

// body{
//     background-color: #222;
// }


// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
//     background-color: #ddd !important;
// }

.ant-divider-horizontal{
    border-top: none ;
    margin: 15px 0;
}

// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//     background-color: inherit !important;
// }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: transparent ;
}

.siteLayout{
    height: 100%;
    
    aside{
         overflow: hidden;
        background-color: #aaa;

        ul{
            border: none;
            background-color: transparent;
        }
    }
}

.ant-btn-primary{
    border: none;
}
